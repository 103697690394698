enum EstimateStatusEnum {
    draft = 1,
    canceled,
    checking,
    fixing,
    agreed,
    agreedBySupervisor,
    agreedByProjectManager,
}

export enum EstimateProjectStatusEnum {
    active = 1,
    completed,
}

export default EstimateStatusEnum;
