export enum EstimateDocumentTypeEnum {
    estimate = 'Smeta',
    additional = 'AdditionalSmeta',
    act = 'Act',
    contract = 'Contract',
}

export enum EstimateTypeProperty {
    Smeta = 'estimate',
    AdditionalSmeta = 'additional',
    Act = 'act',
    Contract = 'contract',
}

export default EstimateDocumentTypeEnum;
