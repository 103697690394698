enum LocalStorageItemNamesEnum {
    DesignFilters = 'designFilters',
    DesignSearch = 'designSearch',
    ModelFilters = 'modelFilters',
    ModelSearch = 'modelSearch',
    EstimateFilters = 'estimateFilters',
    EstimateListActsFilters = 'estimateListActsFilters',
    TaskFilters = 'taskFilters',
    ChartFilters = 'chartFilters',
    ChartSearch = 'chartSearch',
    ReportFilters = 'reportFilters',
}

export default LocalStorageItemNamesEnum;
