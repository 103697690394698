import EstimateStatusEnum from '@/ship/Enums/EstimateStatusEnum';
import i18n from '@/plugins/VueI18n';

export const estimateStatuses = new Map([
    [
        EstimateStatusEnum.draft,
        {
            code: EstimateStatusEnum[EstimateStatusEnum.draft],
            label: i18n.t('draft'),
            color: '#8729D1', // purple (changed),
            background: '#F7F5FF',
            border: '#F3D9FF',
        },
    ],
    [
        EstimateStatusEnum.checking,
        {
            code: EstimateStatusEnum[EstimateStatusEnum.checking],
            label: i18n.t('onChecking'),
            color: '#DA8E1B', // light orange
            background: '#FCF8EF',
            border: '#F3E5B9',
        },
    ],
    [
        EstimateStatusEnum.fixing,
        {
            code: EstimateStatusEnum[EstimateStatusEnum.fixing],
            label: i18n.t('onRevision'),
            color: '#349BFB',
            background: '#ECF7FB',
            border: '#DEEFFF',
        },
    ],
    [
        EstimateStatusEnum.canceled,
        {
            code: EstimateStatusEnum[EstimateStatusEnum.canceled],
            label: i18n.t('canceled'),
            color: '#EB4A14',
            background: '#FCEFEF',
            border: '#FCEFEF',
        },
    ],
    [
        EstimateStatusEnum.agreedBySupervisor,
        {
            code: EstimateStatusEnum[EstimateStatusEnum.agreedBySupervisor],
            label: i18n.t('page.estimates.status.agreedBySupervisor'),
            color: '#90D8B6',
            background: '#FBFFFD',
            border: '#DDF5E4',
        },
    ],

    [
        EstimateStatusEnum.agreedByProjectManager,
        {
            code: EstimateStatusEnum[EstimateStatusEnum.agreedByProjectManager],
            label: i18n.t('page.estimates.status.agreedByProjectManager'),
            color: '#25C278',
            background: '#F3FCF8',
            border: '#C6EED1',
        },
    ],
    [
        EstimateStatusEnum.agreed,
        {
            code: EstimateStatusEnum[EstimateStatusEnum.agreed],
            label: i18n.t('agreed'),
            color: '#008754',
            background: '#E9F8ED',
            border: '#90DDA4',
        },
    ],
]);
