var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat-messages__message",class:{
        'chat-messages__message--mine': _vm.source.isSelf | _vm.isPendingMessage,
        'chat-messages__message--pending': _vm.isPendingMessage,
        'chat-messages__message--rejection': _vm.source.type === _vm.ChatMessageTypeEnum.Rejection,
    }},[_vm._t("default",function(){return [(_vm.isPendingMessage)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.source.error,
                placement: 'bottom',
            }),expression:"{\n                content: source.error,\n                placement: 'bottom',\n            }"}],staticClass:"chat-messages__message-icon chat-messages__message-icon--error"},[_c('ExclamationCircleSVG')],1):[_c('div',{staticClass:"chat-messages__name"},[_c('VUserAvatar',{attrs:{"user":_vm.source.author}}),_c('span',[_vm._v(_vm._s(_vm.source.name))])],1),_c('div',{staticClass:"chat-messages__date"},[_vm._v(_vm._s(_vm._f("simpleTime")(_vm.source.date)))])],(_vm.source.type === _vm.ChatMessageTypeEnum.Rejection)?_c('p',{staticClass:"chat-messages__text",domProps:{"innerHTML":_vm._s(_vm.source.body)}}):_c('p',{staticClass:"chat-messages__text"},[_vm._v(" "+_vm._s(_vm.source.body)+" ")])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }