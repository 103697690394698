export enum RemarkRejectionEnum {
    Created = 1,
    Completed,
    Rejected,
    None,
}

export enum ChatMessageTypeEnum {
    Default = 1,
    Rejection,
}
